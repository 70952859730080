import React from "react";
import Link from "gatsby-link";
import { graphql, useStaticQuery } from "gatsby";

import globe from "../pages/src/img/globe.svg";
const renderPost = (post) => {
  return (
    <div key={post.id} className="country">
      <h2>
        <Link to={`${post.frontmatter.slug}`}>
          <span
            className={`mr-3 flag-icon flag-icon-${post.frontmatter.iso3166_2}`}
            // style={{ height: '1.5em', width: '2em' }}
          />
          {post.frontmatter.countryName}
        </Link>
      </h2>
    </div>
  );
};

export default function Flags() {
  const data = useStaticQuery(query);

  //   console.log("🚀 ~ file: flagGrid.js ~ line 23 ~ Flags ~ data", data);
  const posts = data.allMarkdownRemark.edges;
  //   console.log("🚀 ~ file: index.js ~ line 26 ~ Flags ~ posts", posts);
  return (
    <div className="card p-3 mt-3">
      <h2>Depuis quel pays voulez-vous téléphoner?</h2>
      <div className="row">
        {posts
          .filter((post) => post.node.frontmatter.templateKey === "page-pays")
          .map(({ node: post }) => renderPost(post))}
        <div className="country">
          <h2>
            <Link to={"/autre-pays-notre-partenaire-losali.html"}>
              <span className={"mr-3 flag-icon flag-autre-pays"}>
                <img
                  src={globe}
                  style={{
                    height: "1.5em",
                    width: "1.5em",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                  alt="Appeler depuis d'autres pays"
                />
              </span>
              Autre pays
            </Link>
          </h2>
        </div>
      </div>
    </div>
  );
}

const query = graphql`
  query indexAndCountryQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___countryName] }
      filter: {
        frontmatter: { countryName: { nin: ["Royaume-Uni", "Suisse"] } }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            title
            countryName
            serviceName
            iso3166_2
            templateKey
            date(formatString: "MMMM DD, YYYY")
            slug
          }
          fields {
            slug
          }
        }
      }
    }
    allCountries {
      edges {
        node {
          id
          name
          flag
          alpha2Code
          alpha3Code
        }
      }
    }
  }
`;
